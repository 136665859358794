import React  from "react";
import {Text,Heading,Box, SimpleGrid} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image";
import Link from '../components/gatsbylink'
import {motion} from 'framer-motion'
import MySeo from '../components/seo' 

export default function Eichberg(){
    return(
        <motion.div 
        initial={{opacity:0,paddingBottom:"100px"}}
        animate={{
          opacity:1
        }}
        transition={{duration:.1,delay:.1,ease:"circInOut"}}
        >   
        <MySeo title="Antonie Herzberg" />
        <Box  mt="60px"><Link to="/bacharach"><Text> &rarr; back</Text></Link></Box>
        <SimpleGrid mt="60px" gap={4} columns={[1,1,2]}>
        <Box>
            <Heading fontSize="lg" py="8">Die Familien Eichberg, Halle, Keller und Sommer</Heading>
            
            <Text variant="solid">
            In der Blücherstraße 14 führte Carl Eichberg eine Eisenwarenhandlung. In der Langstraße 54 hatte Ludwig Halle einen Gemüseladen. Abraham Keller betrieb in der Langstraße 43 eine Metzgerei, sein Sohn Josef hatte in der Kranenstraße 6 ebenfalls eine Metzgerei. In der Marktstraße 2 befand sich das Textilgeschäft von Siegfried Sommer. Das Haus in der Langstraße 26 gehörte ihm ebenfalls. In der Blücherstraße 31 wohnte die Familie des Viehhändlers Alfred Sommer. Die Familien engagierten sich in Verbänden und Vereinen, besuchten regelmäßig die Synagoge, unterhielten die Friedhöfe und ihre Kinder gingen in Bacharach in die Schule.
            </Text>
           
            <Text variant="solid">
            Von Ressentiments und Sticheleien hingegen berichtet die Bürgermeisterchronik aus dem Jahr 1931: „Beim Karneval greift der Eisenhändler Eichberg den Leiter der Verwaltung im Stüber’schen Lokal in ungezogener Weise unter Namensnennung an, spricht von „Kofferpacken“ pp und scheint ganz vergessen zu haben, daß man gerade seinem Volk Israel früher einmal in Ägypten dasselbe empfohlen hat und dies auch durchgedrückt hat und daß ausgedehnte Kreise in Deutschland diesem heute mit derselben Wertschätzung begegnen. Aber die Frechheit gewisser Kreise bestätigt nur, wie wenig diesen gegenüber falsche Sentimentalität angebracht ist und erklärt es, weshalb man im Mittelalter mit solchen Burschen wenig Federlesens machte, wenn sie sich den religiösen Bräuchen anderer und Staatseinrichtungen gegenüber Frechheiten heraus nahmen oder gar einen Christen einfach beseitigten, wenn er ihren Unwillen erregt hatte, wie das bei St. Werner der Fall gewesen sein soll. Übrigens ist noch durch einen Stadtverordneten als glaubwürdigen Zeugen festgestellt worden, daß Eichberg diesem erklärt hat, er hätte in der Synagoge seine Glaubensgenossen dazu bestimmt, fortan nicht mehr bei dem (Christen) Borniger (Buchhändler) zu kaufen. (B. liegt seit Monaten krank im Bett). Kein Wunder, daß dem E. kürzlich Hakenkreuze und Epitheta ornantia an das Haus geschmiert worden sind. Nach Aussagen des Beigeordneten Bastian hat E. auch öffentlich erklärt, ihm könnte keiner, da er alle Landwirte im Buch hätte.“
            </Text>
            <Text variant="solid">
            Im April 1935 wurde der Händler Salli Halle angezeigt, weil er angeblich neben Gemüse und Kartoffeln auch Geflügel, Ziegenfleisch, Bückling und Benzin verkauft haben soll. Er dementierte heftig. Es wurde angeordnet, nicht mehr in jüdischen Geschäften einzukaufen. Im Juli 1935 wurden Transparente über die Hauptverkehrsstraßen gespannt: „Der Weg nach Palästina führt nicht durch diese Stadt!“ und „Ohne Lösung der Judenfrage keine Errettung des deutschen Volkes!“. Am 5. Oktober 1938 wurden die Pässe der Jüdinnen und Juden für ungültig erklärt, und jenen, die einen Pass für die Emigration benötigten, wurde ein Pass mit der Kennzeichnung „J“ für Jude ausgestellt. Ein weiteres Gesetz verpflichtete die Jüdinnen und Juden, deren Namen nicht typisch jüdisch klangen, ihren Vornamen die (Schand-)Namen Israel bzw. Sara hinzuzufügen. Viele der alteingesessenen Bacharacher jüdischen Familien verließen nun die Stadt: Carl, Elsa, Hella, Ingeborg und Theresia Eichberg emigrierten nach New York. Max, Salli, Johanna und Wilhelm Halle ebenfalls. Siegfried, Eva Bertha und Ilse Ruth verließen Bacharach schon 1937 und flohen nach San Francisco. Josef, Auguste, Ruth, Kurt David und Helga Keller gingen nach Milwaukee. Ebenso Alfred, Else, Margot, Lotte und Edith, Moritz und Bertha Sommer. Carl Eichberg gelang es, sein gesamtes Bargeld in sein Sofa einzunähen und dies in die USA verschiffen zu lassen. Mit dem Geld baute er eine Hühnerfarm auf.
            </Text>
            <Text variant="solid">
            
            </Text>
            
           
            </Box>
            <Box  my="12">
            <Box pt={["1","1","16"]}>
                    <StaticImage src="../images/bacharach/17.jpg" alt=""/>
                    <Text variant="Bild">Familie Eichberg </Text>
                </Box>
                <Box>
                    <StaticImage src="../images/bacharach/20.jpg" alt=""/>
                    <Text variant="Bild"> </Text>
                </Box>
               
                
            </Box>
            </SimpleGrid>
            </motion.div>
            
    )
}